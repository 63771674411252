import React from "react";

import "react-image-gallery/styles/css/image-gallery.css";
import Header from "../../components/header/Header";
import EecoCNG1 from "../../assets/banners/breeze-blue.webp";
import { Tab } from "@headlessui/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { CgSpinner } from 'react-icons/cg';
import { Helmet } from "react-helmet";
import { CarEnquiryDown } from "../Forms/CarEnquiryDown";

function EecoCNG() {
  return (
    <>
      <Helmet>
        <title>
          Maruti Eeco CNG On Road Price in Secunderabad, Hyderabad & September
          2024 Offers | Maruti Eeco CNG Offers
        </title>
        <meta
          name="title"
          content="Maruti Eeco CNG On Road Price in Secunderabad, Hyderabad & September 2024 Offers | Maruti Eeco CNG Offers"
        />
        <meta
          name="description"
          content="Maruti Eeco CNG in Secunderabad & Hyderabad. Eeco CNG Mileage 31.12KMPL. Best price & offers on Maruti Eeco at Saboo Maruti. Finance facility also available at the dealership. Please visit your nearest Showroom for best deals. Also, Get On-Road Price for Maruti Eeco online. "
        />

        <meta name="author" content="Broaddcast" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://saboomaruti.in/maruti-eeco-on-road-price-in-hyderabad"
        />
        <meta
          property="og:title"
          content="Maruti Eeco CNG On Road Price in Secunderabad, Hyderabad| Eeco CNG  Mileage, Features, Specification | Saboo Maruti"
        />
        <meta
          property="og:description"
          content="Maruti Eeco CNG in Secunderabad & Hyderabad. Eeco CNG Mileage 31.12KMPL. Best price & offers on Maruti Eeco at Saboo Maruti. Finance facility also available at the dealership. Please visit your nearest Showroom for best deals. Also, Get On-Road Price for Maruti Eeco online."
        />
        {/* <meta property='og:image' content='../img/og-tags/dzire.webp' /> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://x.com/saboorksmaruti" />
        <meta
          property="twitter:title"
          content="Maruti Eeco CNG On Road Price in Secunderabad, Hyderabad| Eeco CNG  Mileage, Features, Specification | Saboo Maruti"
        />
        <meta
          property="twitter:description"
          content="Maruti Eeco CNG in Secunderabad & Hyderabad. Eeco CNG Mileage 31.12KMPL. Best price & offers on Maruti Eeco at Saboo Maruti. Finance facility also available at the dealership. Please visit your nearest Showroom for best deals. Also, Get On-Road Price for Maruti Eeco online."
        />
        {/* <meta property='twitter:image' content='../img/og-tags/dzire.webp' /> */}
      </Helmet>
      <Header />
      <img
        src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/Eeco_CNG_Banner.webp"
        className="w-full max-w-full lg:mt-16"
        alt="1"
      />
      <div className="container py-6 pt-6 mx-auto lg:px-0 md:px-3">
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
          <div className="justify-between px-6 space-y-3">
            <p className="text-4xl font-bold uppercase">EECO S-CNG</p>
            <p className="font-bold text-green-900">
              6.58 lakh <sup>*</sup>
              <p className="font-light text-green-900">ex-show room price</p>
            </p>

            <p className="text-s">
              The New EECO S-CNG is among the line-up of environment-friendly
              cars offered by Maruti Suzuki that come equipped with the
              revolutionary S-CNG technology. With 30.1 kW @ 6000 rpm of power
              and 60 Nm @ 3500 rpm of torque, the New EECO S-CNG is peppy to
              drive. The car’s tank capacity allows it to hold up to 60 L(Water
              equivalent filling capacity). The New EECO S-CNG from Maruti
              Suzuki can provide an average mileage is 31.59 km/kg.
            </p>
            <br></br>
            <p className="text-s">
              The New EECO S-CNG features a factory-fitted S-CNG kit that has a
              leak proof design to help ensure that safety is not compromised.
              The use of advanced components contributes to the engine’s longer
              lifespan. The New EECO S-CNG by Maruti Suzuki comes equipped with
              dual interdependent ECUs and an Intelligent Injection System, with
              which it manages to offer smoother pickup, great drivability, and
              an overall superior performance.
            </p>
            <br></br>
            <div className="flex flex-row">
              <div className="basis-1/1">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/eeco/icon1.png"
                  className="w-full max-w-full pr-3"
                  alt="1"
                />
              </div>
              <div className="basis-1/1">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/eeco/icon2.png"
                  className="w-full max-w-full pr-3"
                  alt="1"
                />
              </div>
              <div className="basis-1/1">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/eeco/icon3.png"
                  className="w-full max-w-full pr-3"
                  alt="1"
                />
              </div>
            </div>
          </div>
          <div className="object-contain object-right h-50 w-100 pt-14">
            <img src={EecoCNG1} alt="vehicle_banner" />
          </div>
        </div>
      </div>

      {/* insert form here */}
      {/* <EecoCarEnquiry /> */}
      <CarEnquiryDown title="Eeco CNG" carName="Eeco CNG" />
      <div className="container py-6 pt-6 mx-auto lg:px-0 md:px-3">
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
          <div className="justify-between px-6 space-y-3">
            <p className="text-4xl font-bold uppercase">EECO S-CNG features</p>

            <p className="font-bold text-green-900">
              <div className="object-fill">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/eeco/Eeco-logo-for-CNG-page.png"
                  alt="eeco-logo"
                />
              </div>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <Accordian />
      </div>
    </>
  );
}

// const EecoCarEnquiry = ({ title, carName }) => {
//   const [name, setName] = useState('');
//   const [phone, setPhone] = useState('');
//   const [model, setModel] = useState('Eeco CNG');
//   // const [method, setMethod] = useState();
//   const [loading, setLoading] = useState(false);
//   const [outlet, setOutlet] = useState('');
//   const [submitted, setSubmitted] = useState(false);
//   // Inside your component function
//   // const [showToast, setShowToast] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!name || !phone) {
//       toast.error('Please fill in all required fields.');
//       return;
//     }
//     setLoading(true);
//     try {
//       await axios
//         .post('https://saboogroups.com/admin/api/arena-onRoadPrice', {
//           name: name,
//           phone: phone,
//           // email: email,
//           model: model,
//           outlet: outlet,
//         })
//         .then((res) => {
//           toast.success('Enquiry sent successfully');
//         })
//         .catch((err) => {
//           setLoading(false);
//           toast.error('Something went wrong!');
//           console.log(err);
//         });
//     } catch (error) {
//       // toast.error("Something went wrong!");
//       setLoading(false);
//     }

//     try {
//       await axios
//         .post('https://arena-backend-git-main-arenas-projects.vercel.app/onRoadPrice', {
//           name: name,
//           phone: phone,
//           // email: email,
//           model: model,
//           outlet: outlet,
//         })
//         .then((res) => {
//           toast.success('Enquiry sent successfully');
//         })
//         .catch((err) => {
//           setLoading(false);
//           toast.error('Something went wrong!');
//           console.log(err);
//         });
//     } catch (error) {
//       // toast.error("Something went wrong!");
//       setLoading(false);
//     }

//     // Second API call
//     await axios
//       .get(
//         `https://www.smsstriker.com/API/sms.php?username=saboorks&password=LqHk1wBeI&from=RKSMOT&to=${phone}&msg=Thank you for showing interest in Maruti Suzuki.
//    Our Sales consultant will contact you shortly.

//    Regards
//    RKS Motor Pvt. Ltd.
//    98488 98488
//    www.saboomaruti.in
//    www.saboonexa.in&type=1&template_id=1407168967467983613`
//       )
//       .then((res) => {
//         console.log('SMS API Response:', res.data);
//         setSubmitted(true);
//         setLoading(false);
//       })
//       .catch((err) => {
//         console.error('Error sending SMS:', err);
//         setSubmitted(true);
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     if (submitted) {
//       document.getElementById('arenaCarEnq2').submit();
//     }
//   }, [submitted]);

//   const pattern = /^(?![6-9]{10}$)(?!.*(\d)(?:-?\1){9})[6-9]\d{9}$/;
//   if (phone !== '' && phone.length === 10) {
//     if (!pattern.test(phone)) {
//       toast.error('Enter valid phone number', {
//         theme: 'colored',
//       });
//     }
//   }

//   return (
//     <div className='bg-blue-800 py-12 mt-12'>
//       <div className='container px-5 mx-auto space-y-5 lg:px-0'>
//         <h3 className='text-xl font-normal text-white normal-case'>
//           Get Eeco CNG On-Road price in Hyderabad
//         </h3>
//         <form
//           id='arenaCarEnq2'
//           action={
//             pattern.test(phone) && phone.length === 10
//               ? 'https://crm.zoho.in/crm/WebToLeadForm'
//               : '#'
//           }
//           name='WebToLeads54158000083979838'
//           method={'POST'}
//           acceptCharset='UTF-8'
//         >
//           <input
//             type='text'
//             className='hidden'
//             name='xnQsjsdp'
//             value='c74cc4baa2079f2637d12188693a8bb7a822a54f015337983612fcbc54e9f529'
//           />
//           <input type='hidden' name='zc_gad' id='zc_gad' value='' />
//           <input
//             type='text'
//             className='hidden'
//             name='xmIwtLD'
//             value='adcef2507910e0e3ba3fffde446eb242f3dba817a00c872b6a7d471bc1ce61d0bd840c68a483b37a9012f6016a3ceeb4'
//           />
//           <input
//             type='text'
//             className='hidden'
//             name='actionType'
//             value='TGVhZHM='
//           />
//           <input
//             type='text'
//             className='hidden'
//             name='returnURL'
//             value='https://www.saboomaruti.in/thank-you-for-contact-us'
//           />
//           <div className='grid grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-2'>
//             <div>
//               <input
//                 className='w-full h-10 px-3 border rounded-md outline-none focus:ring-blue-500 focus:border-blue-500'
//                 placeholder='Name'
//                 id='Last_Name'
//                 name='Last Name'
//                 onChange={(e) => setName(e.target.value)}
//                 required
//               />
//             </div>
//             <div>
//               <input
//                 className='w-full h-10 px-3 border rounded-md outline-none focus:ring-blue-500 focus:border-blue-500'
//                 placeholder='Enter your phone number'
//                 minlength='10'
//                 maxlength='10'
//                 id='Phone'
//                 name='Phone'
//                 value={phone}
//                 required
//                 minLength='10'
//                 maxLength='10'
//                 onChange={(e) =>
//                   setPhone(
//                     e.target.value.replace(/[^1-9 ]/g, '') &&
//                       e.target.value.replace(/ /g, '')
//                   )
//                 }
//               />
//               {phone.length > 7 && phone.length < 10 ? (
//                 <small className='text-red-500'>
//                   Phone number must be 10 digits
//                 </small>
//               ) : !pattern.test(phone) && phone.length === 10 ? (
//                 <small className='text-red-500'>Phone number is invalid</small>
//               ) : (
//                 ''
//               )}
//             </div>
//             <div>
//               <select
//                 id='LEADCF6'
//                 name='LEADCF6'
//                 onChange={(e) => setModel(e.target.value)}
//                 className='block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
//                 defaultValue='Eeco CNG'
//               >
//                 <option disabled>Select Model</option>

//                 <option value='Eeco CNG'>Eeco CNG</option>
//               </select>
//             </div>
//             <div>
//               <select
//                 id='LEADCF23'
//                 name='LEADCF23'
//                 onChange={(e) => {
//                   const selectedValue = e.target.value;

//                   // Check if the selected option is not "Select Outlet"
//                   if (selectedValue !== 'Select Outlet') {
//                     // Perform the backend call or set the model state here
//                     setOutlet(selectedValue);
//                   }
//                 }}
//                 defaultValue='Select Outlet'
//                 className='block w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
//               >
//                 <option disabled>Select Outlet</option>
//                 <option value='Somajiguda'>Somajiguda</option>
//                 <option value='Malakpet'>Malakpet</option>
//                 <option value='Secunderabad'>Secunderabad</option>
//                 <option value='Kushaiguda'>Kushaiguda</option>
//                 <option value='Kompally'>Kompally</option>
//                 <option value='Shamirpet'>Shamirpet</option>
//                 <option value='Narsingi'>Narsingi</option>
//                 <option value='Kodangal'>Kodangal</option>
//               </select>
//             </div>
//           </div>
//           {/* <div className='flex items-center my-3 space-x-1'>
//           <input id='comments' name='comments' type='checkbox' required />
//           <label htmlFor='comments' className='font-medium text-gray-200'>
//             I Agree
//           </label>
//         </div>
//         <p className='mb-2 text-gray-200'>
//           <span className='font-semibold'>Disclaimer :</span> I agree that
//           by clicking the ‘Submit’ button below, I am explicitly soliciting
//           a call / Message from Saboo Maruti (RKS Motor Pvt. Ltd) or its
//           Representatives on my ‘Mobile’
//         </p> */}
//           <button
//             type='submit'
//             onClick={handleSubmit}
//             className='inline-flex justify-center h-10 px-4 py-2 mt-4 mb-2 mr-3 text-sm font-medium text-white bg-red-800 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
//           >
//             {loading ? (
//               <div className='flex items-center justify-center'>
//                 <CgSpinner className='h-5 mr-2 text-white animate-spin' />
//                 Loading
//               </div>
//             ) : (
//               'SUBMIT'
//             )}
//           </button>
//           <div className='flex items-start py-1 '>
//             <div className='ml-2 text-sm'>
//               <label
//                 htmlFor='disclaimer'
//                 className='italic font-medium text-gray-700'
//               >
//                 <span className='font-bold text-white'>Disclaimer</span>
//                 <span className='text-white font-extralight'>
//                   : By clicking 'SUBMIT', you agree to our
//                 </span>
//                 <a
//                   href='/maruti-car-terms-and-conditions'
//                   target='_blank'
//                   rel='noopener noreferrer'
//                   className='px-2 text-sm font-bold text-red-700 '
//                 >
//                   Terms and Conditions
//                 </a>
//               </label>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

const Accordian = () => {
  return (
    <>
      <Tab.Group>
        <div className="flex items-center justify-end">
          <Tab.List className="p-5 space-x-1">
            <Tab
              className={({ selected }) =>
                selected
                  ? "bg-blue-800 text-gray-200 px-4 py-2 rounded shadow"
                  : "bg-gray-300 text-black hover:bg-blue-700 hover:text-gray-300 mb-3 px-4 py-2 rounded shadow"
              }
            >
              Features
            </Tab>

            <Tab
              className={({ selected }) =>
                selected
                  ? "bg-blue-800 text-gray-200 px-4 py-2 rounded shadow"
                  : "bg-gray-300 text-black hover:bg-blue-700 hover:text-gray-300 mb-3 px-4 py-2 rounded shadow"
              }
            >
              Specifications
            </Tab>
          </Tab.List>
        </div>
        <div>
          <Tab.Panels>
            {/* exterior */}
            <Tab.Panel>
              <div className="flex items-center justify-between bg-gray-100">
                <p className="flex-1 px-5 py-3 border">Features</p>
                <p className="flex-1 px-5 py-3 border">5 Seater AC, CNG</p>
                <p className="flex-1 px-5 py-3 border">EECO Cargo CNG</p>
                <p className="flex-1 px-5 py-3 border">EECO Cargo CNG AC</p>
              </div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Exterior</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Front Mud Flaps
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Wheel Cover (Center Cap)
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Outside Rear View Mirror
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          (Left & Right)
                        </p>
                        <p className="flex-1 px-5 py-3 border">Standard</p>
                        <p className="flex-1 px-5 py-3 border">Standard</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Tubeless Tyres (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Badging (Decal)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Covered Cargo Cabin (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Door Lock - Driver & Back Door (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Locable Fuel Cap - Petrol (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">INTERIOR</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Interior Colour
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>

                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Both Side Sunvisor
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Molded Floor Carpet
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Rear Cabin Lamp
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Front Cabin Lamp
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          New Colour Seat Matching Interior Colour
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Assist Grip (Co - Driver + Rear)
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Speed Limiting Sticker (LHS Door)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          New Interior Colour (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Flat Cargo Bed (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Floor Carpet (Front)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Co-driver Assist Grip
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        COMFORT AND CONVENIENCE
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Heater</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Sliding Driver Seat
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Reclining Front Seats
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Head Rests - Front Row
                        </p>
                        <p className="flex-1 px-5 py-3 border">Fixed Pillow</p>
                        <p className="flex-1 px-5 py-3 border">Fixed Pillow</p>
                        <p className="flex-1 px-5 py-3 border">Fixed Pillow</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Air Conditioner
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Seat Back Pocket (Co - Driver Seat)
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Integrated Head Rests - Second Row
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Tow Speed Windsheild Wipers (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border"> - </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        SAFETY AND SECURITY
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Headlamp Leveling
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Side Impact Beams
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Seat Belts for all Seats
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          High Mount Stop Lamp
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Child Lock for Sliding Doors & Windows
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Reflector Strips - Front & Rear (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Fire Extinguisher
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Maximum Speed Limit - 80 km/h
                        </p>
                        <p className="flex-1 px-5 py-3 border">-</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        INSTRUMENT PANEL & CONSOLE
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Multi Tripmeter
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          12 Volt Accessory Socket
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>

                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Speedometer Illumination Colour (Amber)
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Digital Meter Cluster{" "}
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          (Fuel level, Odometer & Tripmeter)
                        </p>
                        <p className="flex-1 px-5 py-3 border">(S)</p>
                        <p className="flex-1 px-5 py-3 border">(S)</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Audio 1 Din Box + Cover (S)
                        </p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                        <p className="flex-1 px-5 py-3 border">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Tab.Panel>

            {/* interior */}
            <Tab.Panel>
              <div className="flex items-center justify-between bg-gray-100">
                <p className="flex-1 px-5 py-3 border">Specifications</p>
                <p className="flex-1 px-5 py-3 border">5 Seater AC, CNG</p>
                <p className="flex-1 px-5 py-3 border">EECO Cargo CNG</p>
                <p className="flex-1 px-5 py-3 border">EECO Cargo CNG AC</p>
              </div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Dimensions</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Overall Length
                        </p>
                        <p className="flex-1 px-5 py-3 border">3675 mm</p>
                        <p className="flex-1 px-5 py-3 border">3675 mm</p>
                        <p className="flex-1 px-5 py-3 border">3675 mm</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Overall Width
                        </p>
                        <p className="flex-1 px-5 py-3 border">1475 mm</p>
                        <p className="flex-1 px-5 py-3 border">1475 mm</p>
                        <p className="flex-1 px-5 py-3 border">1475 mm</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Overall Height
                        </p>
                        <p className="flex-1 px-5 py-3 border">1825 mm</p>
                        <p className="flex-1 px-5 py-3 border">1825 mm</p>
                        <p className="flex-1 px-5 py-3 border">1825 mm</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Wheelbase</p>
                        <p className="flex-1 px-5 py-3 border">2350 mm</p>
                        <p className="flex-1 px-5 py-3 border">2350 mm</p>
                        <p className="flex-1 px-5 py-3 border">2350 mm</p>
                      </div>

                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Min. Turning Radius
                        </p>
                        <p className="flex-1 px-5 py-3 border">4.5 m</p>
                        <p className="flex-1 px-5 py-3 border">4.5 m</p>
                        <p className="flex-1 px-5 py-3 border">4.5 m</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Tread (Front)
                        </p>
                        <p className="flex-1 px-5 py-3 border">1280 mm</p>
                        <p className="flex-1 px-5 py-3 border">1280 mm</p>
                        <p className="flex-1 px-5 py-3 border">1280 mm</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Tread (Rear)</p>
                        <p className="flex-1 px-5 py-3 border">1280 mm</p>
                        <p className="flex-1 px-5 py-3 border">1280 mm</p>
                        <p className="flex-1 px-5 py-3 border">1280 mm</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Engine</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Engine Type</p>
                        <p className="flex-1 px-5 py-3 border">G12B</p>
                        <p className="flex-1 px-5 py-3 border">G12B</p>
                        <p className="flex-1 px-5 py-3 border">G12B</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Capacity</p>
                        <p className="flex-1 px-5 py-3 border">1196 CC</p>
                        <p className="flex-1 px-5 py-3 border">1196 CC</p>
                        <p className="flex-1 px-5 py-3 border">1196 CC</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          No. of Cylinders
                        </p>
                        <p className="flex-1 px-5 py-3 border">4</p>
                        <p className="flex-1 px-5 py-3 border">4</p>
                        <p className="flex-1 px-5 py-3 border">4</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Fuel Distribution
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          Multi Point Injection
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          Multi Point Injection
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          Multi Point Injection
                        </p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Max Power (CNG)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          46 kW @6,000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          46 kW @6,000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          46 kW @6,000 RPM
                        </p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Max Power (Petrol)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          54 kW @6,000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          54 kW @6,000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          54 kW @6,000 RPM
                        </p>
                      </div>

                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Max. Torque (CNG)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          85 Nm @ 3000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          85 Nm @ 3000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          85 Nm @ 3000 RPM
                        </p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Max. Torque (Petrol)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          98 Nm @ 3000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          98 Nm @ 3000 RPM
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          98 Nm @ 3000 RPM
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Capacity</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Fuel Tank Capacity (Petrol)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          40 (Gasoline - L)
                        </p>
                        <p className="flex-1 px-5 py-3 border">40 L</p>
                        <p className="flex-1 px-5 py-3 border">40 L</p>
                      </div>

                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Fuel Tank Capacity (CNG Cylinder)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          65 (Water Equivalent - L)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          65 (Water Equivalent - L)
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          65 (Water Equivalent - L)
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Regulation</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Reverse Parking Sensor System
                        </p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Seat Belt Reminder (Dr + Co-Dr)
                        </p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Offset Crash (as per AIS 098)^
                        </p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Child Lock for Doors
                        </p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Speed Alert System
                        </p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">ABS with EBD</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">-</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Driver Airbag
                        </p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">✔</p>
                        <p className="flex-1 px-5 py-3 border ">-</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        Brake / chassis
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Front Brake</p>
                        <p className="flex-1 px-5 py-3 border">Disc</p>
                        <p className="flex-1 px-5 py-3 border">Disc</p>
                        <p className="flex-1 px-5 py-3 border">Disc</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Rear Brake</p>
                        <p className="flex-1 px-5 py-3 border">Drum</p>
                        <p className="flex-1 px-5 py-3 border">Drum</p>
                        <p className="flex-1 px-5 py-3 border">Drum</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">
                          Front Suspension
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          MacPherson strut
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          MacPherson strut
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          MacPherson strut
                        </p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Tyre Size</p>
                        <p className="flex-1 px-5 py-3 border">
                          155 R13 LT 8PR
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          155 R13 LT 8PR
                        </p>
                        <p className="flex-1 px-5 py-3 border">
                          155 R13 LT 8PR
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        DRIVE & TRANSMISSION/EMISSION
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Emission Gas</p>
                        <p className="flex-1 px-5 py-3 border">BS6</p>
                        <p className="flex-1 px-5 py-3 border">BS6</p>
                        <p className="flex-1 px-5 py-3 border">BS6</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border">Transmission</p>
                        <p className="flex-1 px-5 py-3 border">5MT</p>
                        <p className="flex-1 px-5 py-3 border">5MT</p>
                        <p className="flex-1 px-5 py-3 border">5MT</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">WEIGHT</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border ">Kerb Weight</p>
                        <p className="flex-1 px-5 py-3 border">1050 KG</p>
                        <p className="flex-1 px-5 py-3 border">1050 KG</p>
                        <p className="flex-1 px-5 py-3 border">1050 KG</p>
                      </div>
                      <div className="flex items-center justify-between text-base">
                        <p className="flex-1 px-5 py-3 border">
                          Gross Vehicle Weight
                        </p>
                        <p className="flex-1 px-5 py-3 border">1510 KG</p>
                        <p className="flex-1 px-5 py-3 border">1510 KG</p>
                        <p className="flex-1 px-5 py-3 border">1510 KG</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
      <br></br>
      <div className="px-5 text-xs">
        <p>
          <sup>*</sup>Black glass on the vehicles is due to lighting effect.
        </p>
        <p>
          <sup>*</sup>
          <sup>*</sup>Car models and accessories shown may vary from actual
          product. Images are used for illustration purpose only.
        </p>
        <p>
          <sup>*</sup>Test results are Rule 115 of CMVR, 1989.
        </p>
        <p>
          <sup>*</sup>Prices shown above are ex showroom Delhi.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
        <div className="px-6 my-auto space-y-3 text-left">
          <p className="text-4xl font-bold uppercase">EECO S-CNG FAQ's</p>
        </div>
        <div className="object-contain object-right h-50 w-100 pt-14">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border-b-2 hover:bg-gray-200 focus:outline-none">
                  <span className="text-base font-bold uppercase">
                    1. What Fuel Efficiency would i get from EECO?
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pb-2 text-sm tracking-normal">
                  <br></br>

                  <p>
                    There are two variants of Maruti Suzuki Eeco CNG based on
                    the seating capacity you choose. The 5-seater version of
                    Eeco S-CNG comes with a 65L tank and has fuel-efficiency of
                    20.88 km/kg
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border-b-2 hover:bg-gray-200 focus:outline-none">
                  <span className="text-base font-bold uppercase">
                    2. Will it be fine if i get my s-cng car tuned from my
                    personal mechanic?
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pb-2 text-sm tracking-normal">
                  <br></br>
                  <p className="indent-8">
                    If you wish to get your S-CNG car tuned, it is advisable to
                    visit the nearest Maruti Suzuki Arena workshop. Technicians
                    at company authorized workshops undergo special training
                    that gives them all the knowledge required to tune the S-CNG
                    kit for optimum and reliable performance.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-5 py-3 text-sm font-medium text-left border-b-2 hover:bg-gray-200 focus:outline-none">
                  <span className="text-base font-bold uppercase">
                    3. When should i get s-cng kit checked?
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pb-2 text-sm tracking-normal">
                  <br></br>
                  <p className="indent-8">
                    You should get your S-CNG kit as well as the engine checked
                    once every three years. You can either contact the Maruti
                    Suzuki team or visit the nearest Maruti Suzuki authorized
                    service centre to get this done.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
};

export default EecoCNG;
